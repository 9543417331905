var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loadingPage)?_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v(" Lista utenze ")]),_c('v-main',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"w-50 pa-3",attrs:{"label":"Cerca per CUP,Istituzione,Codice Fiscale"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.exportDataAsExcel()}}},[_vm._v(" Esporta excel ")])],1)],1)],1),_c('v-card-text',[(!_vm.dataTableConfiguration)?_c('v-data-table',{attrs:{"headers":_vm.headersTable,"items":_vm.dataSourceTable,"search":_vm.search,"item-key":"programmaOrientamento.cup"},scopedSlots:_vm._u([{key:"item.nomeCognomeLR",fn:function({ item }){return [(item.utenzaLR)?_c('span',{domProps:{"innerHTML":_vm._s(
              item.utenzaLR.anagrafica.nome +
              ' ' +
              item.utenzaLR.anagrafica.cognome
            )}}):_vm._e()]}},{key:"item.nomeCognomeRP",fn:function({ item }){return [(item.utenzaRP)?_c('span',{domProps:{"innerHTML":_vm._s(
              item.utenzaRP.anagrafica.nome +
              ' ' +
              item.utenzaRP.anagrafica.cognome
            )}}):_vm._e()]}},{key:"item.azioni",fn:function({ item }){return [_c('v-menu',{attrs:{"open-on-click":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.vociMenu),function(vocemenu,indexMenu){return _c('v-list-item',{key:indexMenu,on:{"click":function($event){return _vm.manageDialog(indexMenu, item, true)}}},[_vm._v(" "+_vm._s(vocemenu)+" ")])}),1)],1)]}}],null,false,1606640447)}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c('v-card',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Non hai i permessi necessari per accedere alla visualizzazione di questa pagina")])],1),(_vm.dialogStoricoLegali)?_c('v-dialog',{attrs:{"persistent":"","size":"xl"},model:{value:(_vm.dialogStoricoLegali),callback:function ($$v) {_vm.dialogStoricoLegali=$$v},expression:"dialogStoricoLegali"}},[_c('riepilogo-storico-legali',{attrs:{"dati-programma":_vm.propsDialog,"is-content-dialog":true},on:{"close-dialog":function($event){return _vm.manageDialog(1, _vm.propsDialog, false)}}})],1):_vm._e(),(_vm.dialogVisualizzaProgramma)?_c('v-dialog',{attrs:{"persistent":"","size":"xl"},model:{value:(_vm.dialogVisualizzaProgramma),callback:function ($$v) {_vm.dialogVisualizzaProgramma=$$v},expression:"dialogVisualizzaProgramma"}},[_c('RiepilogoProgramma',{attrs:{"dati-programma":_vm.propsDialog,"is-content-dialog":true},on:{"close-dialog":function($event){return _vm.manageDialog(0, _vm.propsDialog, false)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }