<template>
  <v-card elevation="5" v-if="loadingComponent">
    <v-card-title> Riepilogo Programma ID {{ programma.id }} </v-card-title>
    <v-main class="pa-2">
      <v-form ref="form" :readonly="true" disabled>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="CUP*"
              v-model="programma.cup"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Anno scolastico di riferimento*"
              v-model="annoScolastico"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col><h5 class="text-uppercase">Università/AFAM</h5></v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Istituzione"
              v-model="programma.nomeIstituzione"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Nome legale rappresentante"
              v-model="programma.legaleRappresentante.nomeRappresentante"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Cognome legale rappresentante"
              v-model="programma.legaleRappresentante.cognomeRappresentante"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="CF Legale rappresentante*"
              v-model="
                programma.legaleRappresentante.codiceFiscaleRappresentante
              "
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col><h5 class="text-uppercase">Programma</h5></v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Nome referente università/AFAM"
              v-model="programma.nomeReferente"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Cognome referente università/AFAM"
              v-model="programma.cognomeReferente"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Tipo referente"
              v-model="programma.tipoReferente.descrizione"
              outlined
            />
            <!--<v-radio-group
              v-model="programma.tipoReferente"
              :rules="validazioneCampoNecessario()"
          >
            <v-radio
                v-for="tipo in tipiReferenti"
                :key="tipo.id"
                :label="tipo.descrizione"
            ></v-radio>
          </v-radio-group>-->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-text-field
              label="CF Referente*"
              v-model="programma.cfReferente"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <!-- inserita validazione per email vuetify -->
            <v-text-field
              label="Email Referente*"
              v-model="programma.emailReferente"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Recapito telefonico del Referente"
              v-model="programma.telefonoReferente"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Numero di corsi di orientamento programmato*"
              v-model.number="programma.numeroCorsi"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Ore di orientamento programmato*"
              v-model.number="programma.numeroOre"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Soglia minima per ottenere il certificato* (espresso in %)"
              v-model.number="programma.sogliaCertificato"
              outlined
              suffix="%"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-text-field
              label="N° scuole coinvolte*"
              v-model.number="programma.numeroScuole"
              outlined
            ></v-text-field>
            <!--<small class="mt-0 red--text" v-if="!isNumeroScuoleValido"
            >la somma dei campi non può essere diversa dal totale delle
            scuole</small-->
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="N° alunni coinvolti*"
              v-model.number="programma.numeroAlunniCoinvolti"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Assegnazione  Finanziaria"
              v-model="programma.assegnazioneFinanziaria"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Data di avvio programma"
              prepend-icon="mdi-calendar"
              v-model="dataInizioProgramma"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4"
            ><v-text-field
              label="Data di conclusione programma"
              prepend-icon="mdi-calendar"
              v-model="dataFineProgramma"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
      </v-form>
    </v-main>
    <v-card-actions v-if="isContentDialog">
      <v-row>
        <v-col cols="4">
          <v-btn color="warning" outlined @click="closeDialog()">
            Chiudi Finestra
          </v-btn>
        </v-col>
        <v-col cols="8" />
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-progress-circular indeterminate color="blue" />
  </v-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "RiepilogoProgramma",
  components: {},
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  created() {
    this.initialize();
  },
  props: {
    datiProgramma: {
      type: Object,
      required: true,
    },
    isContentDialog: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    loadingComponent: false,
    programma: null,
    dataInizio: null,
    dataFine: null,
    annoScolastico: null,
    dataInizioProgramma: null,
    dataFineProgramma: null,
  }),
  methods: {
    initialize() {
      this.loadingComponent = false;
      if (this.datiProgramma) {
        this.programma = this.datiProgramma.programmaOrientamento;
        this.setupDateProgrammi(this.programma.anno);
        this.annoScolastico =
          this.programma.anno + "/" + (this.programma.anno + 1);
        setTimeout(() => {
          this.loadingComponent = true;
        }, 1000);
      }
    },
    setupDateProgrammi(anno) {
      switch (anno) {
        case 2022:
          this.annoScolastico = anno + "/" + (anno + 1);
          this.dataInizioProgramma = new Date(anno, 10, 1).toLocaleDateString(
            "it-IT"
          );
          this.dataFineProgramma = new Date(anno + 1, 7, 31).toLocaleDateString(
            "it-IT"
          );
          break;

        case 2023:
          this.annoScolastico = anno + "/" + (anno + 1);
          this.dataInizioProgramma = new Date(anno, 8, 1).toLocaleDateString(
            "it-IT"
          );
          this.dataFineProgramma = new Date(anno + 1, 7, 31).toLocaleDateString(
            "it-IT"
          );
          break;

        case 2024:
          this.annoScolastico = anno + "/" + (anno + 2);
          this.dataInizioProgramma = new Date(anno, 8, 1).toLocaleDateString(
            "it-IT"
          );
          this.dataFineProgramma = new Date(anno + 2, 3, 30).toLocaleDateString(
            "it-IT"
          );
          break;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
