<template>
  <v-container fluid>
    <v-card v-if="loadingPage" elevation="4">
      <v-card-title> Lista utenze </v-card-title>
      <v-main>
        <v-row>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              class="w-50 pa-3"
              label="Cerca per CUP,Istituzione,Codice Fiscale"
            />
          </v-col>
          <v-col cols="5">
            <v-btn color="primary" outlined @click="exportDataAsExcel()">
              Esporta excel
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
      <v-card-text>
        <v-data-table
          v-if="!dataTableConfiguration"
          :headers="headersTable"
          :items="dataSourceTable"
          :search="search"
          item-key="programmaOrientamento.cup"
        >
          <!--eslint-disable -->
          <template v-slot:item.nomeCognomeLR="{ item }">
            <span
                v-if="item.utenzaLR"
              v-html="
                item.utenzaLR.anagrafica.nome +
                ' ' +
                item.utenzaLR.anagrafica.cognome
              "
            />
          </template>
          <template v-slot:item.nomeCognomeRP="{ item }">
          <span
              v-if="item.utenzaRP"
              v-html="
                item.utenzaRP.anagrafica.nome +
                ' ' +
                item.utenzaRP.anagrafica.cognome
              "
          />
          </template>
          <template v-slot:item.azioni="{ item }">
             <v-menu
               open-on-click
               bottom>
               <template v-slot:activator="{ on, attrs}"
               >
                 <v-btn
                     class="v-icon"
                     icon
                     color="primary"
                     dark
                     v-bind="attrs"
                     v-on="on">
                   <v-icon> mdi-dots-vertical</v-icon>
                 </v-btn>
               </template>
               <v-list>
                 <v-list-item v-for="(vocemenu, indexMenu) in vociMenu" :key="indexMenu" @click="manageDialog(indexMenu, item, true)">
                   {{ vocemenu }}
                 </v-list-item>
               </v-list>
             </v-menu>
          </template>
          <!-- eslint-enable -->
        </v-data-table>
        <v-progress-circular v-else indeterminate color="primary" />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-alert type="error">
        Non hai i permessi necessari per accedere alla visualizzazione di questa
        pagina</v-alert
      >
    </v-card>
    <!-- dialog storico legali rappresentanti -->
    <v-dialog
      v-if="dialogStoricoLegali"
      v-model="dialogStoricoLegali"
      persistent
      size="xl"
    >
      <riepilogo-storico-legali
        :dati-programma="propsDialog"
        :is-content-dialog="true"
        @close-dialog="manageDialog(1, propsDialog, false)"
      />
    </v-dialog>
    <!-- dialog dettaglio programma -->
    <v-dialog
      v-if="dialogVisualizzaProgramma"
      v-model="dialogVisualizzaProgramma"
      persistent
      size="xl"
    >
      <!-- contenuto dialog visualizza riepilogo dati programma orientamento -->
      <RiepilogoProgramma
        :dati-programma="propsDialog"
        :is-content-dialog="true"
        @close-dialog="manageDialog(0, propsDialog, false)"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ListeUtenzeRepository from "@/api/ministero/ListeUtenzeRepository";
import RiepilogoStoricoLegali from "@/components/UtenzeIstituzioni/RiepilogoStoricoLegaliComponent.vue";
import RiepilogoProgramma from "@/components/UtenzeIstituzioni/RiepilogoProgrammaComponent.vue";

export default {
  name: "utenze-istituzioni",
  components: { RiepilogoProgramma, RiepilogoStoricoLegali },
  computed: {
    ...mapState(["anno", "idStrutture"]),
    ...mapGetters(["isRoleConsulenza", "isRoleMinistero", "isRoleRup"]),
  },
  watch: {
    anno() {
      // devo filtrare i valori per anno
      this.setupDataSourceTable();
    },
    idStrutture() {
      // devo filtrare i valori per idStrutture
      this.setupDataSourceTable();
    },
  },
  data: () => ({
    loadingPage: false,
    dataTableConfiguration: false,
    dataSourceTable: [],
    dataSourceOrigin: [],
    headersTable: [
      {
        text: "Nome Istituzione",
        value: "programmaOrientamento.nomeIstituzione",
        filterable: true,
      },
      { text: "CUP", value: "programmaOrientamento.cup", filterable: true },
      {
        text: "Nome e Cognome",
        value: "nomeCognomeLR",
        filterable: false,
      },
      {
        text: "Indizizzo E-mail",
        value: "utenzaLR.anagrafica.email",
        filterable: false,
      },
      {
        text: "Codice Fiscale",
        value: "utenzaLR.anagrafica.codiceFiscale",
        filterable: true,
      },
      { text: "Nome e Cognome RP", value: "nomeCognomeRP", filterable: false },
      {
        text: "Indirizzo e-mail RP",
        value: "utenzaRP.anagrafica.email",
        filterable: false,
      },
      {
        text: "Codice Fiscale RP",
        value: "utenzaRP.anagrafica.codiceFiscale",
        filterable: true,
      },
      {
        text: "Azioni",
        value: "azioni",
        filterable: false,
      },
    ],
    search: null,
    vociMenu: ["Visualizza programma", "Visualizza storico Legali"],
    dialogVisualizzaProgramma: false,
    dialogStoricoLegali: false,
    propsDialog: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.anno && this.isRoleConsulenza) ||
        (this.isRoleMinistero && this.isRoleRup)
      ) {
        this.loadingPage = true;
        this.getUtentiByRuolo();
      }
    },
    async getUtentiByRuolo() {
      let anno = this.anno;

      this.dataSourceOrigin =
        await ListeUtenzeRepository.getUtenzeIstituzioneByRuolo(
          anno,
          "ROLE_LEGALE_RAPPRESENTANTE,ROLE_REFERENTE_PROGRAMMA"
        );
      this.setupDataSourceTable();
    },
    setupDataSourceTable() {
      let dataSourceConfugured = [];
      this.dataTableConfiguration = false;
      this.dataSourceOrigin.forEach((element) => {
        let annoCompatibile = true;
        let idStruttureCompatibile = true;
        // se l'anno è settato, allora devop recuperare solo i dati del programma con anno corrispondente
        if (this.anno && this.anno !== element.programmaOrientamento.anno) {
          annoCompatibile = false;
        }
        // se l'anno è settato, allora devop recuperare solo i dati del programma con idStrutture corrispondente
        if (
          this.idStrutture &&
          this.idStrutture !== element.programmaOrientamento.idStrutture
        ) {
          idStruttureCompatibile = false;
        }
        // se anno e id strutture, se settati, sono uguali a quelli del porgramma di orientamento li aggiungo
        if (annoCompatibile && idStruttureCompatibile) {
          dataSourceConfugured.push({
            programmaOrientamento: element.programmaOrientamento,
            utenzaLR: this.configureUserByRole(
              "ROLE_LEGALE_RAPPRESENTANTE",
              element.utentiLegaleReferenti
            ),
            utenzaRP: this.configureUserByRole(
              "ROLE_REFERENTE_PROGRAMMA",
              element.utentiLegaleReferenti
            ),
          });
        }
      });
      this.dataSourceTable = dataSourceConfugured;
      this.dataTableConfiguration = false;
    },
    configureUserByRole(typeRole, users) {
      let ruoliUser = [];
      return users.findLast((element) => {
        ruoliUser = element.ruoli.map((role) => {
          return role.descrizione;
        });
        return ruoliUser.includes(typeRole);
      });
    },
    async exportDataAsExcel() {
      const formBody = new FormData();
      formBody.append("data", JSON.stringify(this.dataSourceTable));

      const res = await ListeUtenzeRepository.exportDataExcel(formBody);
      if (res.status === 200) {
        const file = new Blob([res.data], { type: res.data.type });
        const objectUrl = URL.createObjectURL(file);
        const linkToFile = document.createElement("a");
        linkToFile.href = objectUrl;
        linkToFile.download = "report_utenze_legali_referenti_programmi.xlsx";
        linkToFile.click();
        URL.revokeObjectURL(file);
      }
    },
    manageDialog(indexMenu, item, showDialog) {
      this.propsDialog = showDialog ? item : null;
      switch (indexMenu) {
        case 0:
          // dettaglio programma di orientamento
          this.dialogVisualizzaProgramma = showDialog;
          break;

        case 1:
          this.dialogStoricoLegali = showDialog;
          break;
      }
    },
  },
};
</script>
