import Repository from "../Repository";

export default {
  async getListaRendiconti(idStrutture, anno) {
    const url = `api/ministero/${idStrutture}/rendiconti/anno/${anno}/lista-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async getListaCorsiRendiconto(idStrutture, idRendiconto) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/lista-corsi`;
    return (await Repository.get(url)).data;
  },
  async getListaRendicontiSenzaFiltraggio(anno) {
    const url = `api/ministero/anno/${anno}/lista-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async getSingoloRendiconto(idStrutture, idRendiconto) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async getCorsoOrientamento(idStrutture, anno, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/corso/${idCorso}`;
    return (await Repository.get(url)).data;
  },
  async scaricaListaRendicontiExcel(data) {
    const url = `api/ministero/lista-rendiconti-excel1`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "lista_rendiconti_excel1.xlsx";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async scaricaListaRendicontiPdf(data) {
    const url = `api/ministero/lista-rendiconti-pdf`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "lista_rendiconti.pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getFileAllegato(idStrutture, idRendiconto, anno, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/${anno}/file-allegato`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getTipologieChecklistVerificaRendiconto() {
    const url = `api/generici/tipologie-checklist-verifica-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async inserimentoVerificaRendiconto(
    idStrutture,
    idRendiconto,
    formBody,
    salvataggioTassoErrore
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche?salvataggioTassoErrore=${salvataggioTassoErrore}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },
  async modificaVerificaRendiconto(
    idStrutture,
    idRendiconto,
    idVerifica,
    currentStep,
    formBody,
    salvataggioTassoErrore
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche/${idVerifica}?currentStep=${currentStep}&salvataggioTassoErrore=${salvataggioTassoErrore}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },

  async getTemplatePdfChecklistVerificaRendiconto(
    idStrutture,
    idRendiconto,
    formBody
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/verifiche/checklist-verifica-rendiconto-file`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download =
        "pdf_checklist_verifica_rendiconto_" + idRendiconto + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async eliminaFileVerificaChecklistRendiconto(
    idStrutture,
    idRendiconto,
    idVerifica
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche/${idVerifica}/file-checklist`;

    return (await Repository.delete(url)).data;
  },
  async getRendicontiAnnualiIstituzione(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/lista-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async inserimentoVerificaFormaleRendiconto(idStrutture, idRendiconto) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-formali`;
    return (await Repository.post(url)).data;
  },
  async getTipologieChecklistVerificaFormaleRendiconto() {
    const url = `api/generici/tipologie-checklist-verifica-formale-rendiconti`;
    return (await Repository.get(url)).data;
  },
  async modificaVerificaFormaleRendiconto(
    idStrutture,
    idRendiconto,
    idVerifica,
    currentStep,
    formBody
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-formali/${idVerifica}?currentStep=${currentStep}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },

  async getTemplatePdfChecklistVerificaFormaleRendiconto(
    idStrutture,
    idRendiconto,
    formBody
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/verifiche/checklist-verifica-formale-rendiconto-file`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download =
        "pdf_checklist_verifica_formale_rendiconto_" + idRendiconto + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async eliminaFileVerificaFormaleChecklistRendiconto(
    idStrutture,
    idRendiconto,
    idVerifica
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-formali/${idVerifica}/file-checklist`;

    return (await Repository.delete(url)).data;
  },
  async inserimentoVerificaRendicontoUdm(
    idStrutture,
    idRendiconto,
    formBody,
    salvataggioTassoErrore
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-udm?salvataggioTassoErrore=${salvataggioTassoErrore}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getTipologieChecklistVerificaRendicontoUdm() {
    const url = `api/generici/tipologie-checklist-verifica-rendiconti-udm`;
    return (await Repository.get(url)).data;
  },
  async modificaVerificaRendicontoUdm(
    idStrutture,
    idRendiconto,
    idVerifica,
    currentStep,
    formBody,
    salvataggioTassoErrore
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-udm/${idVerifica}?currentStep=${currentStep}&salvataggioTassoErrore=${salvataggioTassoErrore}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },
  async eliminaFileVerificaChecklistRendicontoUdm(
    idStrutture,
    idRendiconto,
    idVerifica
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/${idRendiconto}/verifiche-udm/${idVerifica}/file-checklist-udm`;

    return (await Repository.delete(url)).data;
  },
  async getTemplatePdfChecklistVerificaRendicontoUdm(
    idStrutture,
    idRendiconto,
    formBody
  ) {
    const url = `api/ministero/${idStrutture}/rendiconti/verifiche/checklist-verifica-rendiconto-file-udm`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download =
        "pdf_checklist_verifica_rendiconto_udm_" + idRendiconto + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getPeriodiRiferimento(anno) {
    const url = `api/generici/anno/${anno}/periodi-riferimento-rendicontazioni`;
    return (await Repository.get(url)).data;
  },
};
