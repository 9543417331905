<template>
  <v-container>
    <h1>Dati di Monitoraggio</h1>
    <p>
      Lista dei programmi di orientamento con dettaglio dei Soggetti Attuatori
      che hanno confermato i dati a sistema
    </p>
    <FormFinestraCompilazione />
    <v-col sm="3" md="3">
      <p class="mb-1">Seleziona periodo di riferimento:</p>
      <v-menu
        v-model="monthMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="txtMonth"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="it"
          v-model="month"
          @input="annoMeseMonitoraggi"
          color="primary"
          scrollable
          type="month"
          ref="picker"
        ></v-date-picker>
      </v-menu>
    </v-col>

    <v-col sm="3" md="3">
      <a class="v-btn" @click="downloadExcel()">
        <v-icon> mdi-file-excel</v-icon>
        <span> Scarica tutti i dati trasmessi</span>
      </a>
    </v-col>
    <v-main>
      <!---<BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila"
    />-->
      <div class="pa-4" style="background-color: gainsboro">
        <v-card elevation="2" class="px-4 pb-4">
          <div class="mb-6">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="monitoraggiFiltrato"
                  :items-per-page="5"
                  class="elevation-1"
                  ><!-- eslint-disable -->
                <template v-slot:item.cup="{ item }">
                  <span>{{item.programma.cup}}</span>
                </template>
                <template v-slot:item.clp="{ item }">
                  <span>{{item.programma.clp}}</span>
                </template>
                <template v-slot:item.nomeIstituzione="{ item }">
                  <span>{{item.programma.nomeIstituzione}}</span>
                </template>
                <template v-slot:item.stato="{ item }">
                  <v-chip v-if="item.dataConfermaMonitoraggio" color="success">
                    Confermato
                  </v-chip>
                  <v-chip v-else color="red accent-2">
                    Non Confermato
                  </v-chip>
                </template>
                <template v-slot:item.dataConfermaMonitoraggio="{ item }">
                  <span>{{item.dataConfermaMonitoraggio | formatDate}}</span>
                </template>
               <!--<template v-slot:no-data> nessun risultato </template>-->
                <!-- eslint-enable -->
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-main>
    <v-main> </v-main>
  </v-container>
</template>

<script>
//import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapGetters, mapState } from "vuex";
//import ProgrammiRepository from "@/api/ministero/ProgrammiRepository";
import MonitoraggiRepository from "@/api/ministero/MonitoraggiRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import FormFinestraCompilazione from "@/components/FormFinestraCompilazione.vue";

export default {
  name: "DatiMonitoraggioView",
  components: { FormFinestraCompilazione },
  computed: {
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Dati Monitoraggio",
          disabled: true,
          to: `/scrivania/dati-di-monitoraggio`,
          exact: true,
          link: true,
        },
      ];
    },
    ...mapState(["idStrutture", "anno", "nomeStruttura"]),
    ...mapGetters([]),
  },
  watch: {
    monthMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    month() {
      this.monthMenu = false;
    },
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.getFiltraggioMonitoraggio();
    },
    nomeStruttura() {
      this.getFiltraggioMonitoraggio();
    },
  },
  data: () => ({
    monthMenu: false,
    txtMonth: "",
    month: "",
    loadingProgrammi: false,
    loadingPage: false,
    loading: false,
    monitoraggi: [],
    monitoraggiFiltrato: [],
    headers: [
      { text: "CUP", value: "cup" },
      { text: "CLP", value: "clp" },
      { text: "SOGGETTO ATTUATORE", value: "nomeIstituzione" },
      { text: "STATO CONFERMA DATI", value: "stato" },
      { text: "DATA CONFERMA DATI", value: "dataConfermaMonitoraggio" },
    ],
    datiExcel: {
      cup: null,
      clp: null,
      //nomeIstituzione: null,
      stato: null,
      dataConfermaMonitoraggio: null,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getProgrammiTutti();
      }
    },
    async getProgrammiTutti() {
      this.loadingProgrammi = true;
      this.monitoraggi = await MinisteroRepository.getMonitoraggiTuttiProgrammi(
        this.anno
      );
      this.getFiltraggioMonitoraggio();
      this.loadingProgrammi = false;
    },
    async getFiltraggioMonitoraggio() {
      if (this.idStrutture != null) {
        this.monitoraggiFiltrato = this.monitoraggi.filter(
          (monitoraggio) => monitoraggio.idStrutture == this.idStrutture
        );
      } else {
        this.monitoraggiFiltrato = this.monitoraggi;
      }
      this.loadingProgrammi = false;
    },
    async downloadExcel() {
      let datiExcel = { ...this.monitoraggiFiltrato };
      const data = new FormData();
      data.append("datiExcel", JSON.stringify(datiExcel));
      await MinisteroRepository.scaricaTuttiDatiTrasmesiExcel(data);
    },
    async annoMeseMonitoraggi(dateStr) {
      const month = dateStr.split("-")[1];
      const year = dateStr.split("-")[0];
      this.txtMonth = `${month}, ${year}`;
      this.loadingProgrammi = true;
      this.monitoraggiFiltrato = this.monitoraggi.filter(
        (monitoraggio) => monitoraggio.meseRiferimento == parseInt(month) - 1
      );
      this.loadingProgrammi = false;
    },
    getStatoDatiMonitoraggio(dataConfermaMonitoraggio) {
      if (dataConfermaMonitoraggio) {
        return "Confermato";
      } else {
        return "Non confermato";
      }
    },
    async getMonitoraggiStruttura() {
      this.loadingProgrammi = true;
      this.monitoraggi = await MonitoraggiRepository.getMonitoraggiProgramma(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.loadingProgrammi = false;
    },
  },
};
</script>
<style scoped>
.mb-1 {
  min-height: 5px;
  padding: 20px;
  max-width: 86%;
}
</style>
