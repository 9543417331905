import Repository from "@/api/Repository";

export default {
  async getUtenzeIstituzioneByRuolo(anno, ruoli) {
    // ruoli, se passata come argomento alla funzione, deve essere una stringa con la descrizione dei ruoli, separati da virgola
    const url =
      ruoli && ruoli.length
        ? `api/ministero/report-utenti-programmi/anno/${anno}?ruoli=${ruoli}`
        : `api/ministero/report-utenti-programmi/anno/${anno}`;

    return (await Repository.get(url)).data;
  },
  async exportDataExcel(formBody) {
    const url = `api/ministero/report-utenti-programmi/dati-excel`;
    const headers = { "Content-type": "multipart/form-data" };
    return await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });
  },
};
