<template>
  <v-container fluid>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <h1>Lista Verbali di Campionamento</h1>

    <v-row class="my-6" v-if="loadingPage">
      <v-col cols="12" class="d-flex flex-row-reverse">
        <template v-if="isRoleResponsabileControllo || isRoleConsulenza">
          <router-link
            class="v-btn primary pa-2"
            :to="{
              name: 'nuovoVerbaleControllo',
            }"
          >
            <v-icon left class="white--text"> mdi-plus </v-icon> Nuovo controllo
          </router-link>
        </template>
      </v-col>
    </v-row>

    <div v-if="loadingPage">
      <v-card
        elevation="5"
        v-if="verbaliControlli && verbaliControlli.length"
        :loading="loadingPage"
      >
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(verbale, index) in verbaliControlli"
            :key="index"
          >
            <v-expansion-panel-header color="blue" class="white--text">
              <v-row>
                <v-col cols="2">
                  <v-card-text>
                    Id Verbale <br />
                    <br />
                    <b> {{ verbale.id }} </b>
                  </v-card-text>
                </v-col>
                <v-col cols="5">
                  <v-card-text>
                    Periodo di riferimento delle attività dal
                    <br />
                    <br />
                    <b>
                      {{
                        verbale.dataInizioAttivita
                          ? getTextDate(verbale.dataInizioAttivita)
                          : "Non Disponibile"
                      }}
                      al
                      {{
                        verbale.dataFineAttivita
                          ? getTextDate(verbale.dataFineAttivita)
                          : " Non disponibile"
                      }}
                    </b>
                  </v-card-text>
                </v-col>
                <v-col cols="3">
                  <v-card-text>
                    Data Creazione Campione <br />
                    <br />
                    <b> {{ getTextDate(verbale.dataCreazione) }} </b>
                  </v-card-text>
                </v-col>
                <v-col cols="2">
                  <v-card-text class="text-white">
                    <br />
                    <router-link
                      style="color: white"
                      class="text-white"
                      :to="{
                        name: 'VerbaleCampionamento',
                        params: {
                          anno: anno,
                          idVerbale: verbale.id,
                          readOnly: parseInt(verbale.id) <= 81,
                        },
                      }"
                    >
                      Dettaglio verbale
                      <v-icon dark class="mx-2">mdi-magnify</v-icon>
                    </router-link>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn
                class="ma-3"
                @click="
                  esportaSuExcel(
                    verbale.id,
                    verbale.verbaleCampionamentoRendiconto
                  )
                "
              >
                Esporta su excel <v-icon> mdi-file-excel </v-icon>
              </v-btn>
              <ListaRendicontiComponent
                :selezionabili="true"
                :rendiconti="
                  getRendicontiVerbale(verbale.verbaleCampionamentoRendiconto)
                "
                @keyup="setRendicontiSelezionati"
                @input="setRendicontiSelezionati"
                :key="assegnazioneEseguita"
              />
              <v-card-actions>
                <v-row
                  v-if="rendicontiSelezionati && rendicontiSelezionati.length"
                >
                  <v-col cols="12" class="d-flex flex-row-reverse">
                    <dialog-assegna-incarichi
                      :rendiconti-selezionati="rendicontiSelezionati"
                      v-if="isRoleResponsabileControllo || isRoleConsulenza"
                      @assegnazione-eseguita="manageAssegnazioneEseguita"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" class="d-flex flex-row-reverse">
                    <v-btn
                      v-if="isRoleResponsabileControllo || isRoleConsulenza"
                      :disabled="true"
                      class="primary"
                      text
                    >
                      Assegna incarico
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card v-else class="text-center">
        <v-card-actions class="justify-center">
          <alert-component
            testo="Non è stato creato alcun verbale di controllo"
            tipo-alert="info"
            :width="800"
          />
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import ListaRendicontiComponent from "@/components/Controlli/ListaRendicontiComponent";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import DialogAssegnaIncarichi from "@/components/Controlli/DialogAssegnaIncarichi";
export default {
  components: {
    BreadcrumbComponent,
    ListaRendicontiComponent,
    AlertComponent,
    DialogAssegnaIncarichi,
  },
  name: "ListaVerbaliRendiconti",
  data: () => ({
    loadingPage: false,
    verbaliControlli: [],
    rendicontiSelezionati: [],
    assegnazioneEseguita: 0,
    periodiRiferimento: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: true,
            to: `/scrivania/controlli/nuovo-controllo`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno", "capofila"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  watch: {
    anno() {
      this.loadingPage = false;
      this.initialize();
    },
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getVerbaliControlliRendiconti(this.anno);
      }
    },
    async getVerbaliControlliRendiconti(anno) {
      this.verbaliControlli =
        await ControlliRepository.getVerbaliControlliRendiconti(anno);
      this.loadingPage = true;
    },
    getTextDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      } else {
        return "";
      }
    },
    getRendicontiVerbale(listaRendicontiVerbale) {
      return listaRendicontiVerbale.map((element) => {
        return element.rendiconto;
      });
    },
    setRendicontiSelezionati(value) {
      this.rendicontiSelezionati = value;
    },
    async esportaSuExcel(idVerbale, rendicontiCampionati) {
      const formBody = new FormData();
      formBody.append(
        "rendicontiVerbaleCampionamento",
        JSON.stringify({
          rendiconti: this.getRendicontiVerbale(rendicontiCampionati),
        })
      );
      await ControlliRepository.esportaSuExcelRendicontiCampionati(
        this.anno,
        false,
        idVerbale,
        formBody
      );
    },
    manageAssegnazioneEseguita() {
      this.assegnazioneEseguita += 1;
      this.loadingPage = false;
      setTimeout(() => {
        this.initialize();
      }, 1000);
    },
  },
};
</script>
