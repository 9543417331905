<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col cols="12" class="d-flex flex-row-reverse">
          <v-btn class="primary" text v-bind="attrs" v-on="on">
            Assegna incarico
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-card-title class="h1">Assegna incarico </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <p>
                Lista dei soggetti ai quali è possibile assegnare l'incarico.
              </p>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row class="my-4">
            <v-col col="8">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per cognome"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="utentiControlli"
                :search="ricerca"
                :single-select="true"
                item-key="id"
                show-select
                v-model="utenteSelezionato"
                :items-per-page="10"
                class="elevation-1"
                :loading="loadingRendiconti"
                loading-text="Caricamento dati in corso..."
                no-data-text="Nessun utente disponibile"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn outlined color="blue darken-1" text @click="dialog = false">
          Annulla
        </v-btn>
        <v-btn
          class="primary"
          :disabled="!utenteSelezionato"
          text
          @click="dialogSalva = true"
        >
          Assegna Incarico
        </v-btn>
      </v-card-actions>
      <dialog-conferma
        @callback="assegnaIncarichi()"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />

      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "AssegnaIncarichiUdmView",
  props: {
    rendicontiSelezionati: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    utenteSelezionato: null,
    dialog: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingRendiconti: false,
    utentiControlli: [],
    ricerca: "",
    headers: [
      {
        text: "ID utente",
        align: "start",
        value: "id",
      },
      { text: "Nome", value: "anagrafica.nome" },
      { text: "cognome", value: "anagrafica.cognome" },
      { text: "Codice Fiscale", value: "anagrafica.codiceFiscale" },
    ],
  }),
  components: { DialogConferma },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  created() {
    this.initialize();
  },
  loading: function () {
    return this.loadingRendiconti;
  },
  methods: {
    initialize() {
      if (this.anno != null) {
        this.getUtentiControllo();
      }
    },
    getUtentiControllo: async function () {
      this.loadingRendiconti = true;
      await MinisteroRepository.getUtenti("ROLE_INCARICATO_CONTROLLO").then(
        (data) => {
          if (data) {
            this.utentiControlli = data.filter((item) => {
              let ruoli = item.ruoli
                ? item.ruoli.map((element) => {
                    return element.descrizione;
                  })
                : [];
              return ruoli.includes("ROLE_UDM");
            });
          }
        }
      );
      this.loadingRendiconti = false;
    },
    async assegnaIncarichi() {
      let rendicontiSelezionati = { ...this.rendicontiSelezionati };
      const data = new FormData();
      data.append(
        "rendicontiSelezionati",
        JSON.stringify(rendicontiSelezionati)
      );
      try {
        await ControlliUdmRepository.assegnaIncaricoUdm(
          this.anno,
          this.utenteSelezionato[0].id,
          data
        );
        this.snackbarText = "Rendiconti assegnati all'utente selezionato";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.$emit("assegnazione-eseguita");
        setTimeout(() => {
          this.dialog = false;
        }, 3000);
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "Problema durante l'assegnazione dell'incarico, contattare il supporto se il problema persiste";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped></style>
