<template>
  <v-card elevation="5">
    <v-card-title>
      Storico legali rappresentanti '{{ programma.nomeIstituzione }}'
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="dataSourceLegaliRappresentanti"
        :items="legaliRappresentanti"
        no-results-text="Legali Rappresentanti non trovati"
        no-data-text="Legali Rappresentanti non trovati"
      >
        <!-- eslint-disable -->
        <template v-slot:item.emailRappresentante="{ item }">
          <span v-html="item.emailRappresentante ? item.emailRappresentante : 'Non disponibile'" />
        </template>
        <template v-slot:item.dataInserimento="{ item }">
          <span v-if="item.dataInserimento">
          {{ item.dataInserimento | formatDate }}
          </span>
          <span v-else v-html="'Non disponibile'"></span>
        </template>
        <template v-slot:item.attivo="{ item }">
          <span v-text="checkLegaleAttivo(item.programmi)" />
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="isContentDialog">
      <v-row>
        <v-col cols="4">
          <v-btn color="warning" outlined @click="closeDialog()">
            Chiudi Finestra
          </v-btn>
        </v-col>
        <v-col cols="4" />
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "RiepilogoStoricoLegali",
  components: {},
  computed: {
    ...mapState(["anno", "idStrutture"]),
  },
  created() {
    this.initialize();
  },
  props: {
    datiProgramma: {
      type: Object,
      required: true,
    },
    isContentDialog: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    loadingComponent: false,
    programma: null,
    legaliRappresentanti: [],
    dataSourceLegaliRappresentanti: [
      {
        text: "NOME LEGALE RAPPRESENTANTE",
        align: "start",
        sortable: false,
        value: "nomeRappresentante",
      },
      { text: "COGNOME LEGALE RAPPRESENTANTE", value: "cognomeRappresentante" },
      {
        text: "CODICE FISCALE RAPPRESENTANTE",
        value: "codiceFiscaleRappresentante",
      },
      { text: "EMAIL RAPPRESENTANTE", value: "emailRappresentante" },
      { text: "DATA INSERIMENTO", value: "dataInserimento" },
      { text: "ATTUALE", value: "attivo" },
    ],
  }),
  methods: {
    initialize() {
      if (this.datiProgramma) {
        this.programma = this.datiProgramma.programmaOrientamento;
        this.getLegaliRappresentantiAteneo();
      }
      this.loadingComponent = true;
    },
    async getLegaliRappresentantiAteneo() {
      const idStrutture = parseInt(this.programma.idStrutture);
      await MinisteroRepository.getLegaliRappresentanti(
        parseInt(idStrutture)
      ).then((data) => {
        if (data && data.length > 0) {
          this.legaliRappresentanti = data;
        }
      });
    },
    checkLegaleAttivo(legaliProgrammi) {
      /* filtro i programmi di orientamento associati al legale, dove lui non è più attivo*/
      /* se ci sono programmi di orientamento dove lui non è più attivo,
          vuol dire che è stato sostituito
         viceversa è lui l'ultimo rappresentante legale attivo */
      const programmi = legaliProgrammi.filter(
        (element) =>
          element.attivo === false && element.data_disattiazione == null
      );
      return programmi.length > 0 ? "NO" : "SI";
    },
    async downloadPDF(item, nomeFile) {
      return MinisteroRepository.downloadPdfNominaLegaleRappresentante(
        item.id,
        nomeFile
      );
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
