import Repository from "@/api/Repository";
export default {
  async getFinestraCompilazione(anno, slug) {
    const url = `api/generici/anno/${anno}/finestre-compilazione/${slug}`;
    return (await Repository.get(url)).data;
  },

  async modificaFinestraCompilazione(anno, slug, finestraCompilazione) {
    const url = `api/ministero/anno/${anno}/finestre-compilazione/${slug}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, finestraCompilazione, { headers })).data;
  },
};
