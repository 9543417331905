<template>
  <v-card elevation="4" class="pa-3">
    <v-card-title> Finstra temporale di conferma </v-card-title>
    <div v-if="!anno" class="text-center info-alert">
      <v-alert type="warning" class="pa-2 mb-2">
        Per procedere alla modifica della finestra di compilazione è necessaria
        la selezione di un anno.
      </v-alert>
    </div>
    <v-card-text v-if="loadingForm">
      <v-form v-model="isFormValid" ref="form" lazy-validation>
        <v-row>
          <v-col cols="3">
            <!--<v-text-field
              type="date"
              v-model="form.dataInizio"
              label="Data inizio compilazione"
              :rules="[(v) => !!v || 'Il campo è obbligatorio']"
            />-->
            <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.dataInizio"
                  label="Data inizio compilazione"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.dataInizio"
                no-title
                scrollable
                @input="menu1 = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.dataFine"
                  label="Data Fine compilazione"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.dataFine"
                no-title
                scrollable
                @input="menu2 = false"
              />
            </v-menu>
            <!--<v-text-field
              type="date"
              v-model="form.dataFine"
              label="Data fine
            compilazione"
              :rules="[(v) => !!v || 'Il campo è obbligatorio']"
            />-->
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="primary"
              outlined
              :disabled="!isFormValid || !checkAccessOperation()"
              @click="dialogConferma = true"
            >
              Salva
            </v-btn>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <dialog-conferma
          :dialog.sync="dialogConferma"
          @close-dialog="dialogConferma = false"
          @callback="submitForm()"
        />

        <v-snackbar :color="snackbar.color" v-model="snackbar.value">
          <span v-html="snackbar.text"> </span>
        </v-snackbar>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.info-alert {
  max-width: fit-content;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import DialogConferma from "@/components/DialogConferma.vue";
import finestreRepository from "@/api/ministero/FinestreRepository";
import FinestreRepository from "@/api/ministero/FinestreRepository";

export default {
  name: "form-finestra-compilazione",
  components: { DialogConferma },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isRoleConsulenza", "isRoleMinistero", "isRoleRup"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  data: () => ({
    loadingForm: false,
    form: {
      dataInizio: null,
      dataFine: null,
    },
    dialogConferma: false,
    snackbar: {
      text: "",
      color: "",
      value: false,
    },
    isFormValid: false,
    finestraCompilazione: null,
    chiamataApiFinestraCompilazione: false,
    menu1: false,
    menu2: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadingForm = false;
      if (this.anno) {
        this.getFinestraCompilazioneByAnno();
      }
    },
    loading() {
      return this.loadingForm && this.anno != null;
    },
    async getFinestraCompilazioneByAnno() {
      this.finestraCompilazione = null;
      const anno = parseInt(this.anno);
      const slug = "monitoraggi-ist";
      await finestreRepository
        .getFinestraCompilazione(anno, slug)
        .then((data) => {
          if (data) {
            this.finestraCompilazione = data;
          }
          setTimeout(() => {
            this.setupForm();
          }, 1000);
        });
    },
    setupForm() {
      if (this.finestraCompilazione) {
        this.form.dataInizio = new Date(
          new Date(this.finestraCompilazione.dataInizio) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        this.form.dataFine = new Date(
          new Date(this.finestraCompilazione.dataFine)
        )
          .toISOString()
          .split("T")[0];
        /*this.form.dataFine = new Date(this.finestraCompilazione.dataFine)
          .toISOString()
          .split("T")[0];*/
        this.loadingForm = true;
      }
    },
    async submitForm() {
      if (this.$refs.form.validate() && this.checkInputValues()) {
        const formBody = new FormData();
        formBody.append("finestraCompilazione", JSON.stringify(this.form));

        await FinestreRepository.modificaFinestraCompilazione(
          this.anno,
          this.finestraCompilazione.tipoFinestra.slug,
          formBody
        )
          .then((data) => {
            if (data) {
              this.setSnackbar("success", "Operazione avvenuta con successo");
              this.loadingForm = false;
              this.initialize();
            }
          })
          .catch((reason) => {
            this.setSnackbar(
              "red accent-2",
              reason.response.data ??
                "Un errore generico è stato riscontrato durante la richiesta"
            );
            this.setSnackbar("red accent-2", reason.response.data);
          });
      } else {
        this.setSnackbar(
          "red accent-2",
          "Verificare la compilazione/correttezza dei campi richiesti"
        );
      }
    },
    checkInputValues() {
      const dataInizioInserita = new Date(this.form.dataInizio);
      const dataFineInserita = new Date(this.form.dataFine);
      return !(
        dataInizioInserita > dataFineInserita ||
        dataFineInserita < dataInizioInserita
      );
    },
    setSnackbar(color, text) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
    checkAccessOperation() {
      return this.isRoleRup || this.isRoleConsulenza;
    },
  },
};
</script>
